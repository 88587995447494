/* eslint-disable @20minutes/graphql/template-strings */
import { gql, GraphQLClient } from "graphql-request";
import {
  QueryObserverResult,
  useQuery,
  useMutation,
  useQueryClient,
  UseMutationResult,
} from "@tanstack/react-query";
import {
  CreateIssueAudioInput,
  ExecutiveBriefingTopic as FullExecutiveBriefingTopic,
  Issue as FullIssue,
  IssueFilter,
  IssueInput,
  IssueOutput,
  IssueUpdateInput,
  UpdatedIssueAnalytics,
  UpdatedIssueAnalyticsInput,
} from "@tbml/api-interface/graphql";
import {
  isLicenseError,
  isLicenseFreeError,
} from "@tbml/shared-dependencies/license";
import { useApi } from "../useApi";
import { UseIssuesFieldsFragment } from "./fields";

export const editAnalyticsIssueFragmentName = "EditAnalyticsIssueFields";
export const editAnalyticsIssueFragment = gql`
  fragment ${editAnalyticsIssueFragmentName} on Issue {
    id
    analyticsSectionTitle
    analyticsSummary
    refDate
    widgetsFullView {
      widgetsDateRangeStart
      widgetsDateRangeEnd
      widgetsDateRangeLabel
      tonality {
        enabled
        filters
        caption
        options {
          entity
          region
        }
        points {
          noPositive
          noNegative
          noNeutral
          date
        }
      }
      visibilityAndTonality {
        enabled
        filters
        caption
        options {
          entity
          region
        }
        points {
          noPositive
          noNegative
          noNeutral
          date
          visibility
        }
      }
      sentiment {
        enabled
        filters
        caption
        options {
          entity
          region
        }
        points {
          noPositive
          noNegative
          noNeutral
          date
        }
      }
    }
    analyticsActionButtonIncluded
    analyticsActionButtonName
    analyticsActionButtonLink
    errors
  }
`;

export type UseIssuesResult = {
  query: (
    args:
      | {
          filter?: IssueFilter;
          fragmentName?: never;
          fragment?: never;
          operationName?: string;
          enabled?: boolean;
        }
      | {
          filter?: IssueFilter;
          fragmentName: string;
          fragment: string;
          operationName?: string;
          enabled?: boolean;
        }
  ) => QueryObserverResult<{ issues: Issue[]; count: number }, Error>;
  mutator: UseMutationResult<Issue, Error, IssueInput>;
  updater: UseMutationResult<
    { issues: Issue[]; count: number },
    Error,
    IssueUpdateInput
  >;
  deleter: UseMutationResult<void, Error, IssueFilter>;
  updateAnalytics: UseMutationResult<
    UpdatedIssueAnalytics,
    Error,
    UpdatedIssueAnalyticsInput
  >;
  scheduleAudioGeneration: UseMutationResult<
    void,
    Error,
    CreateIssueAudioInput
  >;
};

export type ExecutiveBriefingTopic = FullExecutiveBriefingTopic;

export type Issue = Omit<FullIssue, "stories" | "storyOrder">;

export const issuesQueryFn =
  (
    client: GraphQLClient,
    filter: IssueFilter,
    fragmentName = "UseIssuesFields",
    fragment = UseIssuesFieldsFragment,
    operationName = "GetIssues"
  ) =>
  async (): Promise<{ issues: Issue[]; count: number }> => {
    const {
      getIssues: { issues, count },
    } = await client
      .request<{ getIssues: IssueOutput }>(
        gql`
          query ${operationName} ($filter: IssueFilter!) {
            getIssues(filter: $filter) {
              issues {
                ...${fragmentName}
              }
              count
            }
          }
          ${fragment}
        `,
        { filter }
      )
      .catch((error) => {
        if (isLicenseError(error)) throw error;
        if (isLicenseFreeError(error)) throw error;
        // Format graphql errors nicer if we have them
        if (error.response?.errors === undefined) {
          throw error;
        }
        const uniqueErrors = new Set();
        error.response.errors.map((err: { message: string }) =>
          uniqueErrors.add(err.message)
        );
        throw new Error(Array.from(uniqueErrors).join(" "));
      });
    return { issues, count };
  };

export const useIssues = (
  {
    fragmentName = "UseIssuesFields",
    fragment = UseIssuesFieldsFragment,
  }: {
    fragmentName: string;
    fragment: string;
  } = {
    fragmentName: "UseIssuesFields",
    fragment: UseIssuesFieldsFragment,
  }
): UseIssuesResult => {
  const { client, token } = useApi();
  const queryClient = useQueryClient();
  const useIssuesQuery = ({
    filter = {},
    fragmentName: queryFragmentName = fragmentName,
    fragment: queryFragment = fragment,
    enabled = true,
    operationName,
  }:
    | {
        filter?: IssueFilter;
        fragmentName?: never;
        fragment?: never;
        operationName?: string;
        enabled?: boolean;
      }
    | {
        filter?: IssueFilter;
        fragmentName: string;
        fragment: string;
        operationName?: string;
        enabled?: boolean;
      }) =>
    useQuery<{ issues: Issue[]; count: number }, Error>({
      queryKey: ["issues", filter, queryFragmentName],
      queryFn: issuesQueryFn(
        client,
        filter ?? {},
        queryFragmentName,
        queryFragment,
        operationName
      ),
      enabled: !!token && enabled,
      retry: (failureCount, error) => {
        if (isLicenseError(error)) return false;
        if (isLicenseFreeError(error)) return false;
        return failureCount !== 3;
      },
    });

  const mutator = useMutation<Issue, Error, IssueInput>({
    mutationFn: async (input) => {
      const {
        mutateIssue: {
          issues: [issue],
        },
      } = await client.request<{
        mutateIssue: { issues: Issue[]; count: number };
      }>(
        gql`
          mutation MutateIssue($input: IssueInput!) {
            mutateIssue(input: $input) {
              issues {
                ...${fragmentName}
              }
              count
            }
          }
          ${fragment}
        `,
        { input }
      );
      return issue;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["issues", {}] });
    },
  });

  const updateAnalyticsMutation = useMutation<
    UpdatedIssueAnalytics,
    Error,
    UpdatedIssueAnalyticsInput
  >({
    mutationFn: async (input) => {
      const { updateAnalytics } = await client.request<{
        updateAnalytics: {
          analyticsSectionTitle: string;
          analyticsSummary: unknown;
          analyticsActionButtonIncluded: boolean;
          analyticsActionButtonName: string;
          analyticsActionButtonLink: string;
        };
      }>(
        gql`
          mutation updateAnalytics($input: UpdatedIssueAnalyticsInput!) {
            updateAnalytics(input: $input) {
              analyticsSectionTitle
              analyticsSummary
              analyticsActionButtonIncluded
              analyticsActionButtonName
              analyticsActionButtonLink
            }
          }
        `,
        { input }
      );
      return updateAnalytics;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["issues", {}] });
    },
  });

  const updater = useMutation<
    { issues: Issue[]; count: number },
    Error,
    IssueUpdateInput
  >({
    mutationFn: async ({ set, filter }) => {
      const {
        updateIssues: { issues, count },
      } = await client.request<{ updateIssues: IssueOutput }, IssueUpdateInput>(
        gql`
          mutation UpdateIssues(
            $set: IssueUpdateFields!
            $filter: IssueFilter!
          ) {
            updateIssues(set: $set, filter: $filter) {
              issues {
                ...${fragmentName}
              }
              count
            }
          }
          ${fragment}
        `,
        { set, filter }
      );
      return { issues, count };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["issues"] });
    },
  });

  const deleteIssues = useMutation<void, Error, IssueFilter>({
    mutationFn: async (filter) => {
      if (!filter.ids) return;
      await client.request(
        gql`
          mutation DeleteIssues($filter: IssueFilter!) {
            deleteIssues(filter: $filter) {
              issues {
                id
              }
              count
            }
          }
        `,
        { filter }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["issues"] });
    },
  });

  const scheduleAudioGeneration = useMutation<
    void,
    Error,
    CreateIssueAudioInput
  >({
    mutationFn: async (input) => {
      await client.request(
        gql`
          mutation scheduleAudioGeneration($input: CreateIssueAudioInput!) {
            createIssueAudio(input: $input) {
              id
            }
          }
        `,
        { input }
      );
    },
  });

  return {
    query: useIssuesQuery,
    mutator,
    updater,
    deleter: deleteIssues,
    updateAnalytics: updateAnalyticsMutation,
    scheduleAudioGeneration,
  };
};
