import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: any;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** Color by HTML standards custom scalar type */
  HtmlColor: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AdditionalCoverage = {
  __typename?: 'AdditionalCoverage';
  id: Scalars['String'];
  productId: Scalars['String'];
  publishedAt: Scalars['DateTime'];
  sortSections: Array<SortSection>;
  updatedAt: Scalars['DateTime'];
};

export type Article = {
  __typename?: 'Article';
  abstracts: Maybe<Array<ArticleAbstract>>;
  articleBroadcastDataId: Maybe<Scalars['Int']>;
  articleSortSectionId: Maybe<Scalars['String']>;
  articleSortSectionRank: Maybe<Scalars['Int']>;
  author: Maybe<Scalars['String']>;
  broadcastData: Maybe<ArticleBroadcastData>;
  clippingLayoutUrl: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  customHeadline: Maybe<Scalars['String']>;
  deeplink: Maybe<Scalars['String']>;
  deltaContent: Maybe<Scalars['JSON']>;
  deltaSnippet: Maybe<Scalars['JSON']>;
  groupOrder: Maybe<Scalars['Int']>;
  headline: Maybe<Scalars['String']>;
  id: Scalars['String'];
  images: Maybe<Array<ArticleImage>>;
  inboxProductIssueId: Maybe<Scalars['String']>;
  ingestionId: Maybe<Scalars['String']>;
  language: Maybe<Scalars['String']>;
  matchedSubscriptions: Maybe<Array<ArticleMatchedSubscription>>;
  mediaForm: Maybe<ArticleMediaForm>;
  mediaId: Maybe<Scalars['Int']>;
  mediaName: Maybe<Scalars['String']>;
  mediaSubForm: Maybe<ArticleMediaSubForm>;
  mediaSubIssue: Maybe<Scalars['String']>;
  oEmbed: Maybe<OEmbed>;
  oEmbedId: Maybe<Scalars['Int']>;
  pages: Maybe<Scalars['String']>;
  priority: Maybe<ArticlePriority>;
  processedAt: Maybe<Scalars['DateTime']>;
  productId: Maybe<Scalars['String']>;
  productType: Maybe<ArticleProductType>;
  publicationTarget: Maybe<Array<ArticlePublicationTarget>>;
  publishedAt: Maybe<Scalars['DateTime']>;
  relatedArticles: Maybe<Array<Maybe<Article>>>;
  relatedTo: Maybe<Article>;
  relatedToId: Maybe<Scalars['String']>;
  section: Maybe<Scalars['String']>;
  snippet: Maybe<Scalars['String']>;
  sortSection: Maybe<ArticleSortSection>;
  source: Maybe<ArticleSource>;
  status: Maybe<ArticleStatus>;
  subHeadline: Maybe<Scalars['String']>;
  supplierDocumentId: Maybe<Scalars['String']>;
  supplierId: Maybe<Scalars['String']>;
  supplierIdShape: Maybe<Scalars['Int']>;
  tags: Maybe<Array<ArticleTag>>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type ArticleAbstract = {
  __typename?: 'ArticleAbstract';
  articleId: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
  deltaContent: Maybe<Scalars['JSON']>;
  id: Scalars['Int'];
  isoCode: Maybe<Scalars['String']>;
};

export type ArticleAbstractInput = {
  content: Scalars['String'];
  deltaContent: Scalars['JSON'];
  isoCode: Scalars['String'];
};

export type ArticleBroadcastData = ArticleBroadcastDataInterface & {
  __typename?: 'ArticleBroadcastData';
  duration: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  publicUrl: Maybe<Scalars['String']>;
  stationId: Maybe<Scalars['Int']>;
  tvEyesSearchId: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export type ArticleBroadcastDataInput = {
  duration?: InputMaybe<Scalars['Int']>;
  publicUrl?: InputMaybe<Scalars['String']>;
  stationId: Scalars['Int'];
  tvEyesSearchId: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
};

export type ArticleBroadcastDataInterface = {
  duration: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  publicUrl: Maybe<Scalars['String']>;
  stationId: Maybe<Scalars['Int']>;
  tvEyesSearchId: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export type ArticleBroadcastDataWithArticleRef = ArticleBroadcastDataInterface & {
  __typename?: 'ArticleBroadcastDataWithArticleRef';
  article: Maybe<Article>;
  duration: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  publicUrl: Maybe<Scalars['String']>;
  stationId: Maybe<Scalars['Int']>;
  tvEyesSearchId: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

/** Allowed Filter fields for Article */
export type ArticleFilter = {
  customerId?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  inboxProductIssueIds?: InputMaybe<Array<Scalars['String']>>;
  issueIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mediaForms?: InputMaybe<Array<ArticleMediaForm>>;
  mediaIds?: InputMaybe<Array<Scalars['Int']>>;
  processedAt?: InputMaybe<TimeRangeInput>;
  productIds?: InputMaybe<Array<Scalars['String']>>;
  publicationTarget?: InputMaybe<Array<ArticlePublicationTarget>>;
  sortSectionIds?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Array<ArticleStatus>>;
};

export type ArticleImage = ArticleImageInterface & {
  __typename?: 'ArticleImage';
  articleId: Maybe<Scalars['String']>;
  caption: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  selected: Maybe<Scalars['Boolean']>;
  url: Maybe<Scalars['String']>;
};

export type ArticleImageInput = {
  articleId: Scalars['String'];
  caption: Scalars['String'];
  selected: Scalars['Boolean'];
  url: Scalars['String'];
};

export type ArticleImageInterface = {
  articleId: Maybe<Scalars['String']>;
  caption: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  selected: Maybe<Scalars['Boolean']>;
  url: Maybe<Scalars['String']>;
};

export type ArticleImageWithArticleRef = ArticleImageInterface & {
  __typename?: 'ArticleImageWithArticleRef';
  article: Maybe<Article>;
  articleId: Maybe<Scalars['String']>;
  caption: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  selected: Maybe<Scalars['Boolean']>;
  url: Maybe<Scalars['String']>;
};

/** Input fields for an Article */
export type ArticleInput = {
  abstracts?: InputMaybe<Array<ArticleAbstractInput>>;
  articleSortSectionRank?: InputMaybe<Scalars['Int']>;
  author?: InputMaybe<Scalars['String']>;
  broadcastData?: InputMaybe<ArticleBroadcastDataInput>;
  clippingLayoutUrl?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  customHeadline?: InputMaybe<Scalars['String']>;
  deeplink?: InputMaybe<Scalars['String']>;
  deltaContent?: InputMaybe<Scalars['JSON']>;
  deltaSnippet?: InputMaybe<Scalars['JSON']>;
  headline?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<ArticleImageInput>>;
  inboxProductIssueId?: InputMaybe<Scalars['String']>;
  ingestionId?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  matchedSubscriptions?: InputMaybe<Array<ArticleMatchedSubscriptionInput>>;
  mediaForm?: InputMaybe<ArticleMediaForm>;
  mediaId?: InputMaybe<Scalars['Int']>;
  mediaName?: InputMaybe<Scalars['String']>;
  mediaSubForm?: InputMaybe<ArticleMediaSubForm>;
  mediaSubIssue?: InputMaybe<Scalars['String']>;
  pages?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<ArticlePriority>;
  processedAt?: InputMaybe<Scalars['DateTime']>;
  productId?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<ArticleProductType>;
  publicationTarget?: InputMaybe<Array<ArticlePublicationTarget>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  section?: InputMaybe<Scalars['String']>;
  snippet?: InputMaybe<Scalars['String']>;
  sortSection?: InputMaybe<ArticleSortSectionInput>;
  status?: InputMaybe<ArticleStatus>;
  subHeadline?: InputMaybe<Scalars['String']>;
  supplierDocumentId?: InputMaybe<Scalars['String']>;
  supplierId?: InputMaybe<Scalars['String']>;
  supplierIdShape?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<Array<ArticleTagInput>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ArticleMatchedSubscription = {
  __typename?: 'ArticleMatchedSubscription';
  highlights: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  name: Maybe<Scalars['String']>;
};

export type ArticleMatchedSubscriptionInput = {
  highlights: Array<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export enum ArticleMediaForm {
  NewsAgency = 'NEWS_AGENCY',
  Online = 'ONLINE',
  Print = 'PRINT',
  RadioStation = 'RADIO_STATION',
  SocialMedia = 'SOCIAL_MEDIA',
  Television = 'TELEVISION'
}

export enum ArticleMediaSubForm {
  AdvertisingPapers = 'ADVERTISING_PAPERS',
  Blogs = 'BLOGS',
  ClassicRadio = 'CLASSIC_RADIO',
  ClassicTv = 'CLASSIC_TV',
  ConsumerPublications = 'CONSUMER_PUBLICATIONS',
  CustomerMagazines = 'CUSTOMER_MAGAZINES',
  DailyNewspapers = 'DAILY_NEWSPAPERS',
  Feed = 'FEED',
  Forums = 'FORUMS',
  InternetRadio = 'INTERNET_RADIO',
  InternetTv = 'INTERNET_TV',
  Microblogs = 'MICROBLOGS',
  Na = 'NA',
  Newsletter = 'NEWSLETTER',
  NewsAgencies = 'NEWS_AGENCIES',
  OnlineNews = 'ONLINE_NEWS',
  PictureSites = 'PICTURE_SITES',
  Podcast = 'PODCAST',
  PressPortals = 'PRESS_PORTALS',
  ProfessionalJournals = 'PROFESSIONAL_JOURNALS',
  QaSites = 'QA_SITES',
  ReviewSites = 'REVIEW_SITES',
  SocialNetworks = 'SOCIAL_NETWORKS',
  VideoSites = 'VIDEO_SITES',
  Websites = 'WEBSITES',
  WeeklyNewspapers = 'WEEKLY_NEWSPAPERS'
}

/** Result from Article queries and mutations */
export type ArticleOutput = {
  __typename?: 'ArticleOutput';
  articles: Array<Maybe<Article>>;
};

export enum ArticlePriority {
  Four = 'FOUR',
  None = 'NONE',
  One = 'ONE',
  Three = 'THREE',
  Two = 'TWO'
}

export enum ArticleProductType {
  MediaReview = 'MEDIA_REVIEW',
  Newsfeed = 'NEWSFEED'
}

export enum ArticlePublicationTarget {
  Tbml = 'TBML'
}

export type ArticleSortSection = {
  __typename?: 'ArticleSortSection';
  id: Scalars['String'];
  name: Maybe<Scalars['String']>;
  rank: Maybe<Scalars['Int']>;
};

export type ArticleSortSectionInput = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  rank?: InputMaybe<Scalars['Int']>;
};

export enum ArticleSource {
  ImportApi = 'IMPORT_API',
  ImportAppManual = 'IMPORT_APP_MANUAL',
  ImportAppPmg = 'IMPORT_APP_PMG',
  ImportAppStm = 'IMPORT_APP_STM',
  ProcessingRule = 'PROCESSING_RULE',
  SubscriptionTrigger = 'SUBSCRIPTION_TRIGGER'
}

export enum ArticleStatus {
  Confirmed = 'CONFIRMED',
  ConfirmedAutomatically = 'CONFIRMED_AUTOMATICALLY',
  Proposed = 'PROPOSED',
  Rejected = 'REJECTED'
}

export type ArticleTag = {
  __typename?: 'ArticleTag';
  id: Scalars['String'];
  localizations: Maybe<Array<ArticleTagLocalization>>;
  subjectAreaId: Maybe<Scalars['Int']>;
  type: Maybe<ArticleTagType>;
};

export type ArticleTagInput = {
  id: Scalars['String'];
  localizations: Array<ArticleTagLocalizationInput>;
  subjectAreaId?: InputMaybe<Scalars['Int']>;
  type: ArticleTagType;
};

export type ArticleTagLocalization = {
  __typename?: 'ArticleTagLocalization';
  articleId: Maybe<Scalars['String']>;
  articleTagId: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isoCode: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
};

export type ArticleTagLocalizationInput = {
  isoCode: Scalars['String'];
  name: Scalars['String'];
};

export enum ArticleTagType {
  Image = 'IMAGE',
  Textual = 'TEXTUAL'
}

/** Input fields for updateArticle mutation */
export type ArticleUpdateInput = {
  filter: ArticleFilter;
  set: ArticleInput;
};

/** Result from an image operation */
export type Asset = {
  __typename?: 'Asset';
  allowCropping: Scalars['Boolean'];
  articleImageId: Maybe<Scalars['Int']>;
  dateCreated: Scalars['DateTime'];
  dateModified: Scalars['DateTime'];
  dominantColors: Maybe<Scalars['JSON']>;
  fileSize: Scalars['Int'];
  id: Scalars['String'];
  mediaHeight: Scalars['Int'];
  mediaKind: AssetMediaType;
  mediaWidth: Scalars['Int'];
  originPath: Scalars['String'];
  showOriginalSize: Scalars['Boolean'];
  sourceId: Scalars['String'];
};

/** Input to link to an existing asset */
export type AssetInput = {
  allowCropping?: InputMaybe<Scalars['Boolean']>;
  articleImageId?: InputMaybe<Scalars['Int']>;
  dateCreated: Scalars['DateTime'];
  dateModified: Scalars['DateTime'];
  dominantColors?: InputMaybe<Scalars['JSON']>;
  fileSize: Scalars['Int'];
  id: Scalars['String'];
  mediaHeight: Scalars['Int'];
  mediaKind: AssetMediaType;
  mediaWidth: Scalars['Int'];
  originPath: Scalars['String'];
  showOriginalSize?: InputMaybe<Scalars['Boolean']>;
  sourceId: Scalars['String'];
};

/** The media type of the asset. Can be IMAGE or VIDEO */
export enum AssetMediaType {
  Image = 'IMAGE'
}

/** Result from cleanOldArticles mutation */
export type CleanOldArticlesOutput = {
  __typename?: 'CleanOldArticlesOutput';
  deletedArticlesCount: Scalars['Int'];
  message: Scalars['String'];
};

export type ContactList = {
  __typename?: 'ContactList';
  contactCount: Scalars['Int'];
  id: Scalars['String'];
  name: Scalars['String'];
};

/** Result from ContactList queries */
export type ContactListOutput = {
  __typename?: 'ContactListOutput';
  contactLists: Array<Maybe<ContactList>>;
};

/** Issue audio input */
export type CreateIssueAudioInput = {
  id: Scalars['String'];
};

/** Result of scheduling audio generation for the issue. */
export type CreateIssueAudioOutput = {
  __typename?: 'CreateIssueAudioOutput';
  id: Scalars['String'];
};

export type Customer = {
  __typename?: 'Customer';
  audioIssueEnabled: Maybe<Scalars['Boolean']>;
  brandColor: Maybe<Scalars['String']>;
  contactInfoEmail: Maybe<Scalars['String']>;
  contactInfoIncluded: Maybe<Scalars['Boolean']>;
  contactInfoName: Maybe<Scalars['String']>;
  contactListIds: Maybe<Array<Scalars['String']>>;
  customerEmail: Maybe<Scalars['String']>;
  defaultIssue: Maybe<Issue>;
  defaultIssueId: Maybe<Scalars['String']>;
  enableAnIContent: Maybe<Scalars['Boolean']>;
  executiveBriefingTopicOrder: Maybe<Array<Scalars['String']>>;
  executiveBriefingTopics: Maybe<Array<ExecutiveBriefingTopic>>;
  gloriaCustomerId: Maybe<Scalars['String']>;
  hasCustomFooter: Maybe<Scalars['Boolean']>;
  hiddenSortSectionsForAddcov: Maybe<Array<ArticleSortSection>>;
  id: Scalars['String'];
  image: Maybe<Asset>;
  inboxAddcovId: Maybe<Scalars['String']>;
  inboxEbsId: Maybe<Scalars['String']>;
  inboxSocialMediaId: Maybe<Scalars['String']>;
  language: Scalars['String'];
  name: Scalars['String'];
  roles: Array<Scalars['String']>;
  selectedWidgets: Array<Scalars['String']>;
  showLicensedContent: Maybe<Scalars['Boolean']>;
  template: Maybe<Template>;
  templateId: Maybe<Scalars['String']>;
};

/** Allowed Filter fields for Customer */
export type CustomerFilter = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  roles?: InputMaybe<Array<Scalars['String']>>;
};

/** Input fields for a Customer */
export type CustomerInput = {
  aNIConfig?: InputMaybe<ANiConfigInput>;
  audioIssueEnabled?: InputMaybe<Scalars['Boolean']>;
  brandColor?: InputMaybe<Scalars['HtmlColor']>;
  contactInfoEmail?: InputMaybe<Scalars['String']>;
  contactInfoIncluded?: InputMaybe<Scalars['Boolean']>;
  contactInfoName?: InputMaybe<Scalars['String']>;
  contactListIds?: InputMaybe<Array<Scalars['String']>>;
  customerEmail?: InputMaybe<Scalars['String']>;
  defaultIssueId?: InputMaybe<Scalars['String']>;
  enableAnIContent?: InputMaybe<Scalars['Boolean']>;
  executiveBriefingTopicOrder?: InputMaybe<Array<Scalars['String']>>;
  executiveBriefingTopics?: InputMaybe<Array<ExecutiveBriefingTopicInput>>;
  existingImage?: InputMaybe<AssetInput>;
  gloriaCustomerId?: InputMaybe<Scalars['String']>;
  hasCustomFooter?: InputMaybe<Scalars['Boolean']>;
  hiddenSortSectionsForAddcov?: InputMaybe<Array<ArticleSortSectionInput>>;
  id: Scalars['String'];
  imageFile?: InputMaybe<Scalars['Upload']>;
  inboxAddcovId?: InputMaybe<Scalars['String']>;
  inboxEbsId?: InputMaybe<Scalars['String']>;
  inboxSocialMediaId?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  roles?: InputMaybe<Array<Scalars['String']>>;
  selectedWidgets?: InputMaybe<Array<Scalars['String']>>;
  showLicensedContent?: InputMaybe<Scalars['Boolean']>;
  template?: InputMaybe<TemplateInput>;
  templateId?: InputMaybe<Scalars['String']>;
};

/** Result from Customer queries and mutations */
export type CustomerOutput = {
  __typename?: 'CustomerOutput';
  customers: Array<Maybe<Customer>>;
};

/** Allowed Filter fields for Customer updates */
export type CustomerUpdateFilter = {
  ids: Array<Scalars['String']>;
};

/** Input fields to update a Customer */
export type CustomerUpdateInput = {
  audioIssueEnabled?: InputMaybe<Scalars['Boolean']>;
  brandColor?: InputMaybe<Scalars['HtmlColor']>;
  contactInfoEmail?: InputMaybe<Scalars['String']>;
  contactInfoIncluded?: InputMaybe<Scalars['Boolean']>;
  contactInfoName?: InputMaybe<Scalars['String']>;
  contactListIds?: InputMaybe<Array<Scalars['String']>>;
  defaultIssue?: InputMaybe<IssueUpdateFields>;
  defaultIssueId?: InputMaybe<Scalars['String']>;
  enableAnIContent?: InputMaybe<Scalars['Boolean']>;
  executiveBriefingTopicOrder?: InputMaybe<Array<Scalars['String']>>;
  executiveBriefingTopics?: InputMaybe<Array<ExecutiveBriefingTopicInput>>;
  gloriaCustomerId?: InputMaybe<Scalars['String']>;
  imageFile?: InputMaybe<Scalars['Upload']>;
  inboxAddcovId?: InputMaybe<Scalars['String']>;
  inboxEbsId?: InputMaybe<Scalars['String']>;
  inboxSocialMediaId?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<Scalars['String']>>;
  selectedWidgets?: InputMaybe<Array<Scalars['String']>>;
  template?: InputMaybe<TemplateUpdateFields>;
  templateId?: InputMaybe<Scalars['String']>;
};

export type ExecutiveBriefingTopic = {
  __typename?: 'ExecutiveBriefingTopic';
  customerId: Maybe<Scalars['String']>;
  deletedAt: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  showStoryOnly: Maybe<Scalars['Boolean']>;
  stories: Maybe<Array<StoryInsideTopic>>;
  subTitle: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ExecutiveBriefingTopicInStory = {
  __typename?: 'ExecutiveBriefingTopicInStory';
  customerId: Maybe<Scalars['String']>;
  deletedAt: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  showStoryOnly: Maybe<Scalars['Boolean']>;
  subTitle: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ExecutiveBriefingTopicInput = {
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  showStoryOnly?: InputMaybe<Scalars['Boolean']>;
  subTitle?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type InboxIssue = {
  __typename?: 'InboxIssue';
  id: Scalars['String'];
  productId: Scalars['String'];
  publishedAt: Scalars['DateTime'];
  sortSections: Array<InboxIssueSortSection>;
  updatedAt: Scalars['DateTime'];
};

/** Allowed Filter fields for InboxIssue */
export type InboxIssueFilter = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  productIds: Array<Scalars['String']>;
};

/** Result from InboxIssue queries */
export type InboxIssueOutput = {
  __typename?: 'InboxIssueOutput';
  inboxIssues: Array<Maybe<InboxIssue>>;
};

export type InboxIssueSortSection = {
  __typename?: 'InboxIssueSortSection';
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
};

export type InboxProduct = {
  __typename?: 'InboxProduct';
  displayName: Scalars['String'];
  id: Scalars['String'];
  sortSections: Array<ArticleSortSection>;
  tags: Array<InboxProductTag>;
  type: InboxProductType;
  updatedAt: Scalars['DateTime'];
};

/** Allowed Filter fields for InboxProduct */
export type InboxProductFilter = {
  ids: Array<Scalars['String']>;
};

export type InboxProductLocalization = {
  __typename?: 'InboxProductLocalization';
  isoCode: Scalars['String'];
  name: Scalars['String'];
};

export type InboxProductTag = {
  __typename?: 'InboxProductTag';
  id: Scalars['String'];
  localizations: Array<InboxProductLocalization>;
  subjectAreaId: Maybe<Scalars['Int']>;
  type: InboxProductTagType;
};

export enum InboxProductTagType {
  Image = 'IMAGE',
  Textual = 'TEXTUAL'
}

export enum InboxProductType {
  MediaReview = 'MEDIA_REVIEW',
  Newsfeed = 'NEWSFEED'
}

export type InboxSortSection = {
  __typename?: 'InboxSortSection';
  id: Scalars['String'];
  name: Scalars['String'];
  rank: Scalars['Int'];
};

export type Issue = {
  __typename?: 'Issue';
  additionalCoverage: Maybe<AdditionalCoverage>;
  analyticsActionButtonIncluded: Maybe<Scalars['Boolean']>;
  analyticsActionButtonLink: Maybe<Scalars['String']>;
  analyticsActionButtonName: Maybe<Scalars['String']>;
  analyticsSectionTitle: Maybe<Scalars['String']>;
  analyticsSummary: Maybe<Scalars['JSON']>;
  articles: Maybe<Array<Article>>;
  audioUrl: Maybe<Scalars['String']>;
  backlogOrder: Maybe<Array<Scalars['String']>>;
  createdAt: Maybe<Scalars['DateTime']>;
  customerId: Maybe<Scalars['String']>;
  errors: Maybe<Array<Maybe<Scalars['String']>>>;
  executiveBriefingTopics: Maybe<Array<ExecutiveBriefingTopic>>;
  id: Scalars['String'];
  inboxIssueId: Maybe<Scalars['String']>;
  inboxProductIssueId: Maybe<Scalars['String']>;
  notifications: Maybe<Array<Notification>>;
  publicationStatus: Maybe<IssuePublicationStatus>;
  refDate: Maybe<Scalars['DateTime']>;
  stories: Maybe<Array<Story>>;
  storyOrder: Maybe<Array<Scalars['String']>>;
  title: Maybe<Scalars['String']>;
  widgetsData: Maybe<WidgetData>;
  widgetsFullView: Maybe<WidgetData>;
};

/** Allowed Filter fields for Issue */
export type IssueFilter = {
  customerId?: InputMaybe<Scalars['String']>;
  executiveBriefingTopicIds?: InputMaybe<Array<Scalars['String']>>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  publicationStatuses?: InputMaybe<Array<IssuePublicationStatus>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

/** Input fields for an Issue */
export type IssueInput = {
  analyticsActionButtonIncluded?: InputMaybe<Scalars['Boolean']>;
  analyticsActionButtonLink?: InputMaybe<Scalars['String']>;
  analyticsActionButtonName?: InputMaybe<Scalars['String']>;
  analyticsSectionTitle?: InputMaybe<Scalars['String']>;
  analyticsSummary?: InputMaybe<Scalars['JSON']>;
  articleIds?: InputMaybe<Array<Scalars['String']>>;
  backlogOrder?: InputMaybe<Array<Scalars['String']>>;
  customerId: Scalars['String'];
  id: Scalars['String'];
  inboxProductIssueId?: InputMaybe<Scalars['String']>;
  publicationStatus?: InputMaybe<IssuePublicationStatus>;
  refDate: Scalars['DateTime'];
  stories?: InputMaybe<Array<StoryInput>>;
  title: Scalars['String'];
};

/** Result from Issue queries and mutations */
export type IssueOutput = {
  __typename?: 'IssueOutput';
  count: Scalars['Int'];
  issues: Array<Issue>;
};

export enum IssuePublicationStatus {
  Published = 'PUBLISHED',
  WorkInProgress = 'WORK_IN_PROGRESS'
}

/** fields for updating an Issue */
export type IssueUpdateFields = {
  analyticsActionButtonIncluded?: InputMaybe<Scalars['Boolean']>;
  backlogOrder?: InputMaybe<Array<Scalars['String']>>;
  inboxProductIssueId?: InputMaybe<Scalars['String']>;
  publicationStatus?: InputMaybe<IssuePublicationStatus>;
  refDate?: InputMaybe<Scalars['DateTime']>;
  storyOrder?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
};

/** Input for updating an issue */
export type IssueUpdateInput = {
  filter: IssueFilter;
  set: IssueUpdateFields;
};

export type Mutation = {
  __typename?: 'Mutation';
  cleanOldArticles: Maybe<CleanOldArticlesOutput>;
  createIssueAudio: CreateIssueAudioOutput;
  createNotification: NotificationOutput;
  createSocialMediaArticle: ArticleOutput;
  deleteArticle: ArticleOutput;
  deleteCustomer: CustomerOutput;
  deleteIssues: IssueOutput;
  deleteStories: StoryOutput;
  mutateCustomer: CustomerOutput;
  mutateIssue: IssueOutput;
  mutateStory: StoryOutput;
  updateAnalytics: UpdatedIssueAnalytics;
  updateArticles: ArticleOutput;
  updateCustomer: CustomerOutput;
  updateIssues: IssueOutput;
  updateStories: StoryOutput;
};


export type MutationCleanOldArticlesArgs = {
  chunkSize?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type MutationCreateIssueAudioArgs = {
  input: CreateIssueAudioInput;
};


export type MutationCreateNotificationArgs = {
  input: NotificationInput;
};


export type MutationCreateSocialMediaArticleArgs = {
  input: SocialMediaArticleInput;
};


export type MutationDeleteArticleArgs = {
  filter: ArticleFilter;
};


export type MutationDeleteCustomerArgs = {
  filter: CustomerFilter;
};


export type MutationDeleteIssuesArgs = {
  filter: IssueFilter;
};


export type MutationDeleteStoriesArgs = {
  filter: StoryFilter;
};


export type MutationMutateCustomerArgs = {
  input: CustomerInput;
};


export type MutationMutateIssueArgs = {
  input: IssueInput;
};


export type MutationMutateStoryArgs = {
  input: StoryInput;
};


export type MutationUpdateAnalyticsArgs = {
  input: UpdatedIssueAnalyticsInput;
};


export type MutationUpdateArticlesArgs = {
  input: ArticleUpdateInput;
};


export type MutationUpdateCustomerArgs = {
  filter: CustomerFilter;
  set: CustomerUpdateInput;
};


export type MutationUpdateIssuesArgs = {
  filter: IssueFilter;
  set: IssueUpdateFields;
};


export type MutationUpdateStoriesArgs = {
  filter: StoryFilter;
  set: StoryUpdateFields;
};

export type Notification = {
  __typename?: 'Notification';
  bccCreator: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  createdByEmail: Maybe<Scalars['String']>;
  createdByName: Maybe<Scalars['String']>;
  customerId: Scalars['String'];
  customerName: Scalars['String'];
  dynamicTemplateData: Scalars['JSON'];
  id: Scalars['String'];
  issueId: Maybe<Scalars['String']>;
  reason: NotificationReason;
  scheduledAt: Maybe<Scalars['DateTime']>;
  sent: Scalars['Boolean'];
  sgBatchId: Maybe<Scalars['String']>;
  templateId: Scalars['String'];
};

/** Filter fields for a Notification query */
export type NotificationFilter = {
  customerIds?: InputMaybe<Array<Scalars['String']>>;
  ids?: InputMaybe<Array<Scalars['String']>>;
};

/** Input fields for a Notification mutation */
export type NotificationInput = {
  customerId: Scalars['String'];
  fallbackTheme: Scalars['String'];
  issueId: Scalars['String'];
  viewerUrl: Scalars['String'];
};

/** Result from Notification mutation */
export type NotificationOutput = {
  __typename?: 'NotificationOutput';
  code: Maybe<Scalars['String']>;
  notifications: Array<Notification>;
};

export enum NotificationReason {
  IssuePublished = 'ISSUE_PUBLISHED'
}

export type OEmbed = {
  __typename?: 'OEmbed';
  author_name: Scalars['String'];
  author_url: Maybe<Scalars['String']>;
  height: Scalars['Int'];
  html: Scalars['String'];
  provider_name: Scalars['String'];
  provider_url: Scalars['String'];
  type: OEmbedType;
  url: Scalars['String'];
  version: Scalars['String'];
  width: Scalars['Int'];
};

export enum OEmbedType {
  Link = 'link',
  Photo = 'photo',
  Rich = 'rich',
  Video = 'video'
}

export type Query = {
  __typename?: 'Query';
  getArticles: Maybe<ArticleOutput>;
  getContactLists: Maybe<ContactListOutput>;
  getCustomers: Maybe<CustomerOutput>;
  getInboxIssues: Maybe<InboxIssueOutput>;
  getInboxProduct: Maybe<InboxProduct>;
  getIsInternalUser: Maybe<Scalars['Boolean']>;
  getIssues: Maybe<IssueOutput>;
  getNotifications: Maybe<NotificationOutput>;
  getStories: Maybe<StoryOutput>;
  getTemplates: Maybe<TemplateOutput>;
  validateGloriaId: Maybe<ValidateGloriaIdOutput>;
};


export type QueryGetArticlesArgs = {
  filter: InputMaybe<ArticleFilter>;
};


export type QueryGetCustomersArgs = {
  filter: InputMaybe<CustomerFilter>;
};


export type QueryGetInboxIssuesArgs = {
  filter: InputMaybe<InboxIssueFilter>;
};


export type QueryGetInboxProductArgs = {
  filter: InputMaybe<InboxProductFilter>;
};


export type QueryGetIssuesArgs = {
  filter: InputMaybe<IssueFilter>;
};


export type QueryGetNotificationsArgs = {
  filter: InputMaybe<NotificationFilter>;
};


export type QueryGetStoriesArgs = {
  filter: InputMaybe<StoryFilter>;
};


export type QueryValidateGloriaIdArgs = {
  input: ValidateGloriaIdInput;
};

export type SentimentData = {
  __typename?: 'SentimentData';
  caption: Scalars['String'];
  enabled: Scalars['Boolean'];
  filters: Maybe<Scalars['JSON']>;
  options: Maybe<SentimentOptions>;
  points: Maybe<Array<SentimentDataPoint>>;
};

export type SentimentDataPoint = {
  __typename?: 'SentimentDataPoint';
  date: Scalars['Date'];
  noNegative: Scalars['Int'];
  noNeutral: Scalars['Int'];
  noPositive: Scalars['Int'];
};

export type SentimentOptions = {
  __typename?: 'SentimentOptions';
  entity: Array<Scalars['String']>;
  region: Array<Scalars['String']>;
};

/** Input fields for a Social Media Article */
export type SocialMediaArticleInput = {
  inboxProductId: Scalars['String'];
  issueId?: InputMaybe<Scalars['String']>;
  mediaId: Scalars['Int'];
  source: Scalars['String'];
};

export type SortSection = {
  __typename?: 'SortSection';
  count: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  name: Maybe<Scalars['String']>;
};

export type Story = StoryInterface & {
  __typename?: 'Story';
  actionButtonIncluded: Maybe<Scalars['Boolean']>;
  actionButtonLink: Maybe<Scalars['String']>;
  actionButtonName: Maybe<Scalars['String']>;
  allAssets: Maybe<Array<StoryAsset>>;
  articleOrder: Maybe<Array<Scalars['String']>>;
  articles: Maybe<Array<Article>>;
  assets: Maybe<Array<StoryAsset>>;
  broadcastData: Maybe<ArticleBroadcastData>;
  editorial: Maybe<Scalars['JSON']>;
  executiveBriefingTopic: Maybe<ExecutiveBriefingTopicInStory>;
  executiveBriefingTopicId: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image: Maybe<Asset>;
  issueId: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type StoryAsset = {
  __typename?: 'StoryAsset';
  articleBroadcastData: Maybe<ArticleBroadcastDataWithArticleRef>;
  articleImage: Maybe<ArticleImageWithArticleRef>;
  articleImageId: Maybe<Scalars['Int']>;
  asset: Maybe<Asset>;
  id: Maybe<Scalars['Int']>;
  selected: Maybe<Scalars['Boolean']>;
  storyId: Maybe<Scalars['String']>;
};

/** State for asset in story context */
export type StoryAssetSelection = {
  articleBroadcastDataId?: InputMaybe<Scalars['Int']>;
  articleImageId?: InputMaybe<Scalars['Int']>;
  assetId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  imageFile?: InputMaybe<Scalars['Upload']>;
  isSelected?: InputMaybe<Scalars['Boolean']>;
};

/** Allowed Filter fields for Story */
export type StoryFilter = {
  executiveBriefingTopicId?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  issueId?: InputMaybe<Scalars['String']>;
  withinTheSameTopicForStoryId?: InputMaybe<Scalars['String']>;
};

/** Input fields for a Story */
export type StoryInput = {
  actionButtonIncluded?: InputMaybe<Scalars['Boolean']>;
  actionButtonLink?: InputMaybe<Scalars['String']>;
  actionButtonName?: InputMaybe<Scalars['String']>;
  allowImageCropping?: InputMaybe<Scalars['Boolean']>;
  articleIds: Array<Scalars['String']>;
  broadcastDataId?: InputMaybe<Scalars['Int']>;
  editorial: Scalars['JSON'];
  executiveBriefingTopicId?: InputMaybe<Scalars['String']>;
  existingImage?: InputMaybe<AssetInput>;
  id: Scalars['String'];
  imageFile?: InputMaybe<Scalars['Upload']>;
  issueId?: InputMaybe<Scalars['String']>;
  showImageOriginalSize?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
};

export type StoryInsideTopic = StoryInterface & {
  __typename?: 'StoryInsideTopic';
  actionButtonIncluded: Maybe<Scalars['Boolean']>;
  actionButtonLink: Maybe<Scalars['String']>;
  actionButtonName: Maybe<Scalars['String']>;
  allAssets: Maybe<Array<StoryAsset>>;
  articleOrder: Maybe<Array<Scalars['String']>>;
  articles: Maybe<Array<Article>>;
  assets: Maybe<Array<StoryAsset>>;
  broadcastData: Maybe<ArticleBroadcastData>;
  editorial: Maybe<Scalars['JSON']>;
  executiveBriefingTopicId: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image: Maybe<Asset>;
  issueId: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type StoryInterface = {
  actionButtonIncluded: Maybe<Scalars['Boolean']>;
  actionButtonLink: Maybe<Scalars['String']>;
  actionButtonName: Maybe<Scalars['String']>;
  allAssets: Maybe<Array<StoryAsset>>;
  articleOrder: Maybe<Array<Scalars['String']>>;
  articles: Maybe<Array<Article>>;
  assets: Maybe<Array<StoryAsset>>;
  broadcastData: Maybe<ArticleBroadcastData>;
  editorial: Maybe<Scalars['JSON']>;
  executiveBriefingTopicId: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image: Maybe<Asset>;
  issueId: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

/** Result from Story queries and mutations */
export type StoryOutput = {
  __typename?: 'StoryOutput';
  stories: Array<Story>;
};

/** fields for updating a Story */
export type StoryUpdateFields = {
  actionButtonIncluded?: InputMaybe<Scalars['Boolean']>;
  actionButtonLink?: InputMaybe<Scalars['String']>;
  actionButtonName?: InputMaybe<Scalars['String']>;
  allowImageCropping?: InputMaybe<Scalars['Boolean']>;
  articleIds?: InputMaybe<Array<Scalars['String']>>;
  articleImageId?: InputMaybe<Scalars['Int']>;
  assetId?: InputMaybe<Scalars['String']>;
  assetsSelection?: InputMaybe<Array<StoryAssetSelection>>;
  broadcastDataId?: InputMaybe<Scalars['Int']>;
  editorial?: InputMaybe<Scalars['JSON']>;
  executiveBriefingTopicId?: InputMaybe<Scalars['String']>;
  imageFile?: InputMaybe<Scalars['Upload']>;
  setImageFileAsPrimary?: InputMaybe<Scalars['Boolean']>;
  showImageOriginalSize?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Input for updating a story */
export type StoryUpdateInput = {
  filter: StoryFilter;
  set: StoryUpdateFields;
};

export type Template = {
  __typename?: 'Template';
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** Input fields for a Template */
export type TemplateInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** Result from EmailTemplate Query */
export type TemplateOutput = {
  __typename?: 'TemplateOutput';
  templates: Array<Template>;
};

/** Fields for updating a Template */
export type TemplateUpdateFields = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

/** Input fields for a time range */
export type TimeRangeInput = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type TonalityData = {
  __typename?: 'TonalityData';
  caption: Scalars['String'];
  enabled: Scalars['Boolean'];
  filters: Maybe<Scalars['JSON']>;
  options: Maybe<TonalityOptions>;
  points: Maybe<Array<TonalityDataPoint>>;
};

export type TonalityDataPoint = {
  __typename?: 'TonalityDataPoint';
  date: Scalars['Date'];
  noNegative: Scalars['Int'];
  noNeutral: Scalars['Int'];
  noPositive: Scalars['Int'];
};

export type TonalityFilters = {
  entity?: InputMaybe<Array<Scalars['String']>>;
  region?: InputMaybe<Array<Scalars['String']>>;
};

export type TonalityOptions = {
  __typename?: 'TonalityOptions';
  entity: Array<Scalars['String']>;
  region: Array<Scalars['String']>;
};

/** updated issue analytics */
export type UpdatedIssueAnalytics = {
  __typename?: 'UpdatedIssueAnalytics';
  analyticsActionButtonIncluded: Scalars['Boolean'];
  analyticsActionButtonLink: Maybe<Scalars['String']>;
  analyticsActionButtonName: Maybe<Scalars['String']>;
  analyticsSectionTitle: Scalars['String'];
  analyticsSummary: Scalars['JSON'];
};

/** updated issue analytics */
export type UpdatedIssueAnalyticsInput = {
  analyticsActionButtonIncluded: Scalars['Boolean'];
  analyticsActionButtonLink?: InputMaybe<Scalars['String']>;
  analyticsActionButtonName?: InputMaybe<Scalars['String']>;
  analyticsSectionTitle: Scalars['String'];
  analyticsSummary: Scalars['JSON'];
  issueId: Scalars['String'];
  sentimentCaption: Scalars['String'];
  sentimentEnabled: Scalars['Boolean'];
  sentimentFilters: TonalityFilters;
  tonalityCaption: Scalars['String'];
  tonalityEnabled: Scalars['Boolean'];
  tonalityFilters: TonalityFilters;
  visibilityAndTonalityCaption: Scalars['String'];
  visibilityAndTonalityEnabled: Scalars['Boolean'];
  visibilityAndTonalityFilters: TonalityFilters;
  widgetsDateRangeEnd: Scalars['Date'];
  widgetsDateRangeLabel: Scalars['String'];
  widgetsDateRangeStart: Scalars['Date'];
};

/** validates if given customer id for the analytics exists. */
export type ValidateGloriaIdInput = {
  id: Scalars['String'];
};

/** Result of the id validation. */
export type ValidateGloriaIdOutput = {
  __typename?: 'ValidateGloriaIdOutput';
  isValid: Scalars['Boolean'];
};

export type VisibilityAndTonality = {
  __typename?: 'VisibilityAndTonality';
  caption: Scalars['String'];
  enabled: Scalars['Boolean'];
  filters: Maybe<Scalars['JSON']>;
  options: Maybe<VisibilityAndTonalityOptions>;
  points: Maybe<Array<VisibilityAndTonalityDataPoint>>;
};

export type VisibilityAndTonalityDataPoint = {
  __typename?: 'VisibilityAndTonalityDataPoint';
  date: Scalars['Date'];
  noNegative: Scalars['Int'];
  noNeutral: Scalars['Int'];
  noPositive: Scalars['Int'];
  visibility: Scalars['Int'];
};

export type VisibilityAndTonalityOptions = {
  __typename?: 'VisibilityAndTonalityOptions';
  entity: Array<Scalars['String']>;
  region: Array<Scalars['String']>;
};

export type WidgetData = {
  __typename?: 'WidgetData';
  sentiment: Maybe<SentimentData>;
  tonality: Maybe<TonalityData>;
  tonalityOptions: Maybe<TonalityOptions>;
  visibilityAndTonality: Maybe<VisibilityAndTonality>;
  widgetsDateRangeEnd: Scalars['Date'];
  widgetsDateRangeLabel: Scalars['String'];
  widgetsDateRangeStart: Scalars['Date'];
};

export type ANiConfigInput = {
  configuration: Scalars['JSON'];
  id: Scalars['String'];
  name: Scalars['String'];
};


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ArticleBroadcastDataInterface": [
      "ArticleBroadcastData",
      "ArticleBroadcastDataWithArticleRef"
    ],
    "ArticleImageInterface": [
      "ArticleImage",
      "ArticleImageWithArticleRef"
    ],
    "StoryInterface": [
      "Story",
      "StoryInsideTopic"
    ]
  }
};
      export default result;
    