/* eslint-disable @20minutes/graphql/template-strings */
import { gql, GraphQLClient } from "graphql-request";
import {
  QueryObserverResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  ArticleFilter,
  Article as FullArticle,
  SocialMediaArticleInput,
  ArticleOutput,
} from "@tbml/api-interface/graphql";
import {
  isLicenseError,
  isLicenseFreeError,
} from "@tbml/shared-dependencies/license";
import { useApi } from "../useApi";
import { UseArticlesFieldsFragment } from "./fields";

export type Article = FullArticle;

export type UseArticlesResult = {
  query: (
    args:
      | {
          filter?: ArticleFilter;
          fragmentName?: never;
          fragment?: never;
          enabled?: boolean;
        }
      | {
          filter?: ArticleFilter;
          fragmentName: string;
          fragment: string;
          enabled?: boolean;
        }
  ) => QueryObserverResult<Article[], Error>;
  mutator: UseMutationResult<ArticleOutput, Error, SocialMediaArticleInput>;
};

export const articleQueryFn =
  (
    client: GraphQLClient,
    filter: ArticleFilter,
    fragmentName = "UseArticlesFieldsFragment",
    fragment = UseArticlesFieldsFragment
  ) =>
  async (): Promise<Article[]> => {
    const {
      getArticles: { articles },
    } = await client.request<{ getArticles: { articles: Article[] } }>(
      gql`
            query GetArticles($filter: ArticleFilter!) {
                getArticles(filter: $filter) {
                    articles {
                        ...${fragmentName}
                    }
                }
            }
            ${fragment}
        `,
      { filter }
    );

    return articles;
  };

export const useArticles = (
  {
    fragmentName = "UseArticlesFieldsFragment",
    fragment = UseArticlesFieldsFragment,
  }: {
    fragmentName?: string;
    fragment?: string;
  } = {
    fragmentName: "UseArticlesFieldsFragment",
    fragment: UseArticlesFieldsFragment,
  }
): UseArticlesResult => {
  const queryClient = useQueryClient();
  const { client, token } = useApi();

  const useArticleQuery = ({
    filter = {},
    fragmentName: queryFragmentName = fragmentName,
    fragment: queryFragment = fragment,
    enabled,
  }:
    | {
        filter?: ArticleFilter;
        fragmentName?: never;
        fragment?: never;
        enabled?: boolean;
      }
    | {
        filter?: ArticleFilter;
        fragmentName: string;
        fragment: string;
        enabled?: boolean;
      }) =>
    useQuery<Article[], Error>({
      queryKey: ["articles", filter, queryFragmentName],
      queryFn: async () => {
        const query = articleQueryFn(
          client,
          filter ?? {},
          queryFragmentName,
          queryFragment
        );
        const articles = await query();
        articles.forEach((article) => {
          queryClient.setQueryData(["article", article.id], article);
        });
        return articles;
      },
      enabled: enabled && !!token,
      retry: (failureCount, error) => {
        if (isLicenseFreeError(error)) return false;
        if (isLicenseError(error)) return false;
        return failureCount !== 3;
      },
    });

  const mutator = useMutation<ArticleOutput, Error, SocialMediaArticleInput>({
    mutationFn: async (input) => {
      const {
        createSocialMediaArticle: { articles },
      } = await client.request<{
        createSocialMediaArticle: ArticleOutput;
      }>(
        gql`
          mutation CreateSocialMediaArticle($input: SocialMediaArticleInput!) {
            createSocialMediaArticle(input: $input) {
              articles {
                id
              }
            }
          }
        `,
        { input }
      );
      return { articles };
    },
  });

  return {
    query: useArticleQuery,
    mutator,
  };
};
